import {  AiOutlineTransaction } from 'react-icons/ai'
import { BsSpeedometer2 } from 'react-icons/bs'
import { MdOutlineRealEstateAgent, MdOutlineTram } from "react-icons/md";

export const adminlistItems = [
    // {
    //     name: 'Dashboard',
    //     path: '/admin/dashboard',
    //     icon: <AiFillHome size={'1.5rem'} />
    // },
    {
        name: 'Meters',
        path: '/admin/meters',
        icon: <BsSpeedometer2 size={'1.5rem'} />
    },
    {
        name: 'Transactions',
        path: '/admin/transactions',
        icon: <AiOutlineTransaction size={'1.5rem'} />
    },
    {
        name: 'Estates',
        path: '/admin/estates',
        icon: <MdOutlineRealEstateAgent size={'1.5rem'} />
    },
    {
        name: 'Generate',
        path: '/admin/generate',
        icon: <MdOutlineTram size={'1.5rem'} />
    }

]