import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigation } from "../components/helpers";
import styled from "styled-components";
import { AdminPages } from "../pages";

const Admin = () => {
  const [show, setShow] = useState(true)
  return (
    <AppContainer>
      <Routes>
        <Route path="/dashboard" index={true} element={<AdminPages.Dashboard show={show} />} />
        <Route path="/meters" element={<AdminPages.Meter show={show} />} />
        <Route path="/meters/new" element={<AdminPages.AddMeter show={show} />} />
        <Route path="/generate" element={<AdminPages.GenerateToken />} />
        <Route path="/estates" element={<AdminPages.Estate show={show} />} />
        <Route path="/estate/:id" element={<AdminPages.EstateInfo show={show} />} />
        <Route path="/transactions" element={<AdminPages.Transactions show={show} />} />
        <Route path="/transactions/update/:transactionId" element={<AdminPages.UpdateTrx show={show} />} />
      </Routes>
      <Navigation show={show} setShow={setShow} />
    </AppContainer>
  );
};

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #F8F0E5;
  overflow: hidden;
`;

export default Admin;
