import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const Spinner = () => {
  const location = useLocation()
  return (
    <App>
        <Loader />
        {location.pathname.split('/')[1] === 'receipt' && <Card><p>Processing your receipt</p></Card>}
    </App>
  )
}


const App = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 15rem auto;
`
const Loader = styled.div`
  border: 2px solid #CD5C08;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

const Card = styled.div`
width: max-content;
height: auto;
padding: 1rem;
background-color: transparent; 
margin-top: 30px;

p {
color: #CC5B08;
font-size: 16px;
font-weight: bold;
}
`;
export default Spinner