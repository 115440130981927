import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
// import { Energy } from '../../assets/images'
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
// import { api } from '../../components/hooks';
import { useNavigate } from "react-router-dom";
import { Alert, Loader } from "../../components/helpers";
import api from "../../service/api";

const AdminLogin = () => {
  const [userData, setUserData] = useState({
    emailPhone: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { password, emailPhone } = userData;

  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value.replace(" ", ""),
      });
    },
    revealPassword() {
      setShowPassword(!showPassword);
    },

    async onSubmit(
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLDivElement>
    ) {
      try {
        e.preventDefault();
        setLoading(true);
        const data = await r.handleLogin();
        if (data?.status === "success") {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.details));
          setShowAlert(true);
          setAlertMessage(data?.data?.message);
          setColor("#64CCC5");
          setTimeout(() => setShowAlert(false), 3000);
          setTimeout(() => navigate("/admin/meters"), 4000);
        } else {
          setShowAlert(true);
          setAlertMessage(data?.message);
          setTimeout(() => setShowAlert(false), 3000);
        }
        setLoading(false);
      } catch (error) {}
    },

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") {
        r.onSubmit(e);
      }
    },

    async handleLogin() {
      try {
        const body = {
          emailPhone,
          password,
        };
        const data = await api.req("/admin/signin", "POST", body);

        // console.log(data, "data");
        return data;
      } catch (error: any) {}
    },
  };
  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Admin Login</title>
      </Helmet>
      <Wrapper>
        {/* <ImageWrapper>
                <Image src={Energy} alt='logo' />
            </ImageWrapper> */}
        <LoginContainer>
          <h1 style={{ fontSize: "1.5rem", color: "#cd5c08" }}>Welcome Back</h1>
          <p style={{ fontSize: "1rem ", color: "#cd5c08" }}>
            Enter your credentials to access your account.
          </p>
          <FormContainer onSubmit={(e) => r.onSubmit(e)}>
            <Input>
              <input
                type="text"
                placeholder="Enter your email"
                name="emailPhone"
                onChange={(e) => r.onChange(e)}
                value={emailPhone}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #FDC029",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                className="password"
                onChange={(e) => r.onChange(e)}
                value={password}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #FDC029",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
              {password.length > 0 && (
                <Icon onClick={() => r.revealPassword()}>
                  {showPassword ? (
                    <MdOutlineVisibility
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Hide password"
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Show password"
                    />
                  )}
                </Icon>
              )}
            </Input>
            {loading ? (
              <Button>
                <Loader color={"#fff"} />
              </Button>
            ) : (
              <Button
                onClick={(e) => r.onSubmit(e)}
                className={!emailPhone || !password ? "disabled" : undefined}
              >
                Admin Login
              </Button>
            )}
          </FormContainer>
          <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
            Dont have an account?{" "}
            <span
              style={{ color: "#cd5c08" }}
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </span>
          </p>
        </LoginContainer>
      </Wrapper>
      {showAlert && <Alert message={alertMessage} color={color} />}
    </AppContainer>
  );
};

/** STYLES */
const AppContainer = styled.div`
  background-color: "none";
  min-height: 100vh;
  width: 100%;
  display: "flex";
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: none;
`;
// const ImageWrapper = styled.div`

// `;
// const Image = styled.img`
// width: 80px;
// height: 80px;
// `;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  width: 30%;
  height: 100%;
  flex-direction: column;
  padding: 50px 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 5px;
  margin: 1rem;

  h1 {
    font-size: 19px;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    align-self: center;
    color: #cd5c08;
    margin: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  }
`;

const FormContainer = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    margin-top: 30px;
    border: 1px solid #f0f0f0;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #fde406;
    padding: 1rem;
    outline: "";
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 15px;
  color: white;
  outline: none;
  background-color: #cd5c08;
  font-size: 16px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #cd5c08;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 2.5rem;
  display: flex;
  right: 1rem !important;
  cursor: pointer;
`;

export default AdminLogin;
