import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Sterling } from '../../assets/images';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../service/api';
import { Tools } from '../../utils';
import { Screen } from '../../components/styles';
import { Spinner } from '../../components/helpers';

interface TransactionData {
    meterNumber: string;
    meterType: string;
    token: string;
    product: string;
    amount: number;
    email: string;
    phone: string;
    estate: string;
    reference: string;
    createdAt: string;
}

const Receipt = () => {
    const [loading, setLoading] = useState(true);
    const [transactionData, setTransactionData] = useState<TransactionData | null>(null);
    const [estate, setEstate] = useState<string | null>(null);
    const navigate = useNavigate();
    const { id } = useParams(); 

    useEffect(() => {

        const storedEstate = localStorage.getItem("estate");
        setEstate(storedEstate);

        const fetchTransaction = async () => {
            try {
                const authToken = localStorage.getItem("token");
                const data = await api.req(`/transaction/${id}`, "GET", null, authToken);
                if (data.status === "success") {
                    setTransactionData(data.data.details);
                } else {
                    console.error("Failed to fetch transaction data:", data.message);
                }
            } catch (error) {
                console.error("Error fetching transaction data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTransaction();
    }, [id]);

    if (loading) {
        return (
            <AppContainer>
                <Spinner />
            </AppContainer>
        );
    }

    if (!transactionData) {
        return (
            <AppContainer>
                <p>Error loading transaction data. Please try again later.</p>
            </AppContainer>
        );
    }

    return (
        <AppContainer>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Receipt</title>
            </Helmet>
            <Wrapper>
                <ButtonWrapper>
                    <Button onClick={() => Tools.snapshot("receipt", "payment_receipt")}>Download Receipt</Button>
                    <Button onClick={() => navigate("/homePage")}>Back to homepage</Button>
                </ButtonWrapper>
                <Card id='receipt'>
                    <Icon title='Sterling Tech' />
                    <Header>Receipt</Header>
                    <Container>
                        <Text>Meter Number: <span>{transactionData.meterNumber}</span></Text>
                        <Text>Meter Type: <span>{transactionData.meterType}</span></Text>
                        <Text>Token: <span>{transactionData.token}</span></Text>
                        <Text>Product: <span>{transactionData.product}</span></Text>
                        <Text>Amount: <span>{Tools.formatCurrency(transactionData.amount)}</span></Text>
                        <Text>Email: <span>{transactionData.email}</span></Text>
                        <Text>Phone Number: <span>{transactionData.phone}</span></Text>
                        <Text>Estate: <span>{estate}</span></Text>
                        <Text>Reference: <span>{transactionData.reference}</span></Text>
                        <Text>Date: <span>{moment(transactionData.createdAt).format("YYYY-MM-DD h:mma")}</span></Text>
                    </Container>
                    <Enquiry>
                        <p>For complaints and enquiries, kindly reach us through:</p>
                        <p><span>Phone Number:</span> 09030841776</p>
                        <p><span>Email:</span> contact@sterlingtech.com.ng</p>
                    </Enquiry>
                </Card>
            </Wrapper>
        </AppContainer>
    );
};


const AppContainer = styled.div`
    background-color: #CD5C08;
    min-height: 100vh;
    width: 100%;
    padding-top: 70px;
    overflow-x: hidden;
    ${Screen.iPhone` padding-top: 30px `}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${Screen.iPhone` width: 100% `}
`;

const Container = styled.div`
    width: 80%;
    margin-top: 2rem;
`;

const Card = styled.div`
    display: flex;
    width: 450px;
    height: auto;
    flex-direction: column;
    padding: 50px 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    margin: 1rem 2rem 2rem;
    position: relative;
    ${Screen.iPhone` width: 90%; `}
`;

const Icon = styled.div`
    width: 20px;
    position: absolute;
    top: 1rem;
    left: 1rem;
    content: url(${Sterling});
    cursor: pointer;
`;

const Header = styled.h2`
    text-align: center;
    color: #CD5C08;
`;

const Button = styled.div`
    display: flex;
    margin-top: 30px;
    padding: 15px 10px;
    border-radius: 5px;
    color: #CD5C08;
    outline: none;
    font-weight: 800;
    background-color: white;
    font-size: 12px;
    cursor: pointer;
    justify-content: center;
    &:hover {
        background-color: #444;
    }
    ${Screen.iPhone` padding: 15px; `}
`;

const Text = styled.h5`
    padding-bottom: 1rem;
    span {
        font-weight: 400;
    }
`;

const ButtonWrapper = styled.div`
    width: 450px;
    display: flex;
    justify-content: space-between;
    ${Screen.iPhone`
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `}
`;

const Enquiry = styled.div`
    margin-top: 2rem;
    p {
        font-size: 14px;
        padding-bottom: .5rem;
        span {
            color: #CD5C08;
        }
    }
`;

export default Receipt;
