import React, { useEffect, useState } from "react";
import TransactionComponent from "./transactionComponent";
import api from "../../service/api";
import styled from "styled-components";
import moment from "moment"; 
import { AiOutlineArrowLeft, AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface TransactionOptions {
  currency: string;
}

interface Transaction {
  id: string;
  meterNumber: string;
  product: string;
  time: string;
  status: string;
  token: string;
  amount: string;
  createdAt: string;
  options: TransactionOptions;
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [singleTransaction, setSingleTransaction] =
    useState<Transaction | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [copyAlert, setCopyAlert] = useState<boolean>(false); 
  const navigate = useNavigate();

  useEffect(() => {
    getTransactionHistory();
  }, []);

  const getTransactionHistory = async () => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const data = await api.req("/transaction/history", "GET", null, authToken);

      if (data.status === "success") {
        setTransactions(data.data.details.rows);
      } else {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true);
      setAlertMessage("Failed to load transaction history.");
    } finally {
      setLoading(false);
    }
  };

  const getActionColor = (status: string): string => {
    switch (status) {
      case "confirmed":
        return "green";
      case "pending":
        return "yellow";
      case "failed":
        return "red";
      default:
        return "gray";
    }
  };

  const getSingleTransactionDetails = async (id: string) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("token");
      const data = await api.req(`/transaction/${id}`, "GET", null, authToken);

      if (data.status === "success") {
        setSingleTransaction(data.data.details);
        setIsModalOpen(true);
      } else {
        setShowAlert(true);
        setAlertMessage(data.message);
        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (error) {
      console.error(error);
      setShowAlert(true);
      setAlertMessage("Failed to load transaction details.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSingleTransaction(null);
  };

  const formatDateTime = (dateString: string): string => {
    return moment(dateString).format("MMMM Do YYYY, h:mm a");
  };

  const copyTokenToClipboard = (token: string) => {
    navigator.clipboard.writeText(token);
    setCopyAlert(true); 
    setTimeout(() => setCopyAlert(false), 2000); 
  };

  return (
    <div
      style={{
        background: "white",
        width: "100%",
        height: "100%",
        padding: "0rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: "1rem",
          display: "flex",
          gap: "3rem",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <AiOutlineArrowLeft
          color={"black"}
          size={20}
          onClick={() => {
            navigate("/homePage");
          }}
        />
        <p style={{ fontSize: "1.5rem" }}>Transaction History</p>
      </div>

      <div style={{ width: "100%" }}>
        {loading ? (
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <p>Loading...</p>
          </div>
        ) : transactions.length > 0 ? (
          transactions.map((transaction) => (
        <TransactionComponent
        getSingleTransaction={() => getSingleTransactionDetails(transaction.id)}
        key={transaction.id}
        id={transaction.id}  
        meterNumber={transaction.meterNumber}
        product={transaction.product}
        time={formatDateTime(transaction.createdAt)}
        color={getActionColor(transaction.status)}
        amount={transaction.amount}
      />
          ))
        ) : (
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "100vh",
            }}
          >
            <p>No transactions found.</p>
          </div>
        )}
      </div>

      {showAlert && (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100vh",
          }}
        >
          <p style={{ color: "red" }}>{alertMessage}</p>
        </div>
      )}

      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CloseIcon onClick={closeModal}>
              <AiOutlineCloseCircle size={24} />
            </CloseIcon>
            <h2 style={{ marginBottom: "1rem" }}>Transaction Details</h2>
            {singleTransaction && (
              <div>
                <p>Amount: {singleTransaction.amount}</p>
                <p>Meter Number: {singleTransaction.meterNumber}</p>
                <p>Product: {singleTransaction.product}</p>
                <FlexContainer>
                <TokenText>Token: {singleTransaction.token}</TokenText>
                <CopyButton onClick={() => copyTokenToClipboard(singleTransaction.token)}>
                  Copy
                </CopyButton>
              </FlexContainer>
                <p>Status: {singleTransaction.status}</p>
                <Button
                  onClick={() => navigate(`/receipt/${singleTransaction.id}`)}
                >
                  View Receipt
                </Button>
              </div>
            )}
          </ModalContent>
        </Modal>
      )}

      {copyAlert && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#CD5C08",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`;

const TokenText = styled.span`
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyButton = styled.button`
  padding: 5px 10px;
  background-color: #cd5c08;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: #b84e06;
  }
`;


const Button = styled.button`
  padding: 10px;
  margin-top: 20px;
  background-color: #cd5c08;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #45a049;
  }
`;

export default Dashboard;
