import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Alert, Loader } from "../../components/helpers";
import api from "../../service/api";

const Login = () => {
  const [userData, setUserData] = useState({
    emailPhone: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    emailPhone: "",
    newPassword: "",
  });
  
  const navigate = useNavigate();

  const { password, emailPhone } = userData;

  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value.replace(" ", ""),
      });
    },
    revealPassword() {
      setShowPassword(!showPassword);
    },

    async onSubmit(
      e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement>
    ) {
      try {
        e.preventDefault();
        setLoading(true);
        const data = await r.handleLogin();
        if (data?.status === "success") {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.details));
          setShowAlert(true);
          setAlertMessage(data?.data?.message);
          setColor("#64CCC5");
          setTimeout(() => setShowAlert(false), 3000);
          setTimeout(() => navigate("/homePage"), 4000);
        } else {
          setShowAlert(true);
          setAlertMessage(data?.message);
          setTimeout(() => setShowAlert(false), 3000);
        }
        setLoading(false);
      } catch (error) {
        // Handle login error
      }
    },

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") {
        r.onSubmit(e as any);
      }
    },

    async handleLogin() {
      try {
        const body = {
          emailPhone,
          password,
        };
        const data = await api.req("/user/login", "POST", body);
        return data;
      } catch (error) {
        // Handle error
      }
    },
  };

  const handleForgotPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForgotPasswordData({
      ...forgotPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleForgotPasswordSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await api.req("/user/forgot", "PATCH", forgotPasswordData);
      if (data?.status === "success") {
        setAlertMessage("Password reset successful");
        setColor("#64CCC5");
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      } else {
        setAlertMessage(data?.message);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
      }
      setShowForgotPasswordModal(false);
    } catch (error) {
      setAlertMessage("Error resetting password");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <Wrapper>
        <LoginContainer>
          <h1 style={{ fontSize: "1.5rem", color: "#CD5C08" }}>Welcome Back</h1>
          <p style={{ fontSize: "1rem ", color: "#CD5C08" }}>
            Enter your credentials to access your account.
          </p>
          <FormContainer onSubmit={(e) => r.onSubmit(e)}>
            <Input>
              <input
                type="text"
                placeholder="Enter your email"
                name="emailPhone"
                onChange={(e) => r.onChange(e)}
                value={emailPhone}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                className="password"
                onChange={(e) => r.onChange(e)}
                value={password}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
              {password.length > 0 && (
                <Icon onClick={() => r.revealPassword()}>
                  {showPassword ? (
                    <MdOutlineVisibility
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Hide password"
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Show password"
                    />
                  )}
                </Icon>
              )}
            </Input>
            {loading ? (
              <Button>
                <Loader color={"#fff"} />
              </Button>
            ) : (
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => r.onSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}
                className={!emailPhone || !password ? "disabled" : undefined}
              >
                Login
              </Button>
            )}
          </FormContainer>
          <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
            Forgot your password?{" "}
            <span
              style={{ color: "#CD5C08", cursor: "pointer" }}
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Reset here
            </span>
          </p>
          <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
            Don’t have an account?{" "}
            <span
              style={{ color: "#CD5C08" }}
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </span>
          </p>
        </LoginContainer>
      </Wrapper>

      {showForgotPasswordModal && (
        <ModalOverlay>
          <ModalContent>
            <h2>Reset Password</h2>
            <FormContainer onSubmit={handleForgotPasswordSubmit}>
              <Input>
                <input
                  type="text"
                  name="emailPhone"
                  placeholder="Enter your email"
                  value={forgotPasswordData.emailPhone}
                  onChange={handleForgotPasswordChange}
                  required
                  style={{
                    border: "2px solid #CD5C08",
                    borderRadius: "20px",
                    color: "black",
                  }}
                />
              </Input>
              <Input>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Enter new password"
                  value={forgotPasswordData.newPassword}
                  onChange={handleForgotPasswordChange}
                  required
                  style={{
                    border: "2px solid #CD5C08",
                    borderRadius: "20px",
                    color: "black",
                  }}
                />
              </Input>
              <Button type="submit">Reset Password</Button>
            </FormContainer>
            <Button onClick={() => setShowForgotPasswordModal(false)}>
              Cancel
            </Button>
          </ModalContent>
        </ModalOverlay>
      )}

      {showAlert && <Alert message={alertMessage} color={color} />}
    </AppContainer>
  );
};

/** STYLES */
const AppContainer = styled.div`
  background-color: #F8F0E5;
  min-height: 100vh;
  width: 100%;
  display: "flex";
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 50px 30px;
  background-color: #F8F0E5;
  border-radius: 10px;
  margin: 1rem;

  h1 {
    font-size: 19px;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    align-self: center;
    color: #CD5C08;
    margin: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: gray;
    margin: 0;
  }
`;

const FormContainer = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    margin-top: 30px;
    border: 1px solid #CD5C08;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #fde406;
    padding: 1rem;
    outline: "";
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = styled.div`
  position: relative;
`;

const Button = styled.button`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 15px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  border: none;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #cd5c08;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 2.5rem;
  display: flex;
  right: 1rem !important;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }
`;

export default Login;
