import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineCreditCard, AiOutlineHistory } from "react-icons/ai";
import { Sterling } from "../../assets/images";
import api from "../../service/api";
import moment from "moment";

const HomePage = () => {
  const navigate = useNavigate();
  const [lastPayment, setLastPayment] = useState({ amount: "0", date: "" });

  useEffect(() => {
    getLastTransaction();
  }, []);

  const getLastTransaction = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const data = await api.req("/transaction/history", "GET", null, authToken);
      if (data.status === "success" && data.data.details.rows.length > 0) {
        const latestTransaction = data.data.details.rows[0];
        setLastPayment({
          amount: `₦${parseFloat(latestTransaction.amount).toFixed(2)}`, 
          date: moment(latestTransaction.createdAt).format("MMMM Do YYYY"), 
        });
      }
    } catch (error) {
      console.error("Failed to load the last transaction:", error);
    }
  };

  return (
    <StyledContainer>
      <Header>
        <Logo src={Sterling} alt="Electricity Pay" />
        <Profile><span>JD</span></Profile>
      </Header>

      <Actions>
        <ActionButton onClick={() => navigate("/payment")}>
          <AiOutlineCreditCard size={28} />
          <span>Recharge Meter</span>
        </ActionButton>
        <ActionButton onClick={() => navigate("/dashboard")}>
          <AiOutlineHistory size={28} />
          <span>Transaction History</span>
        </ActionButton>
      </Actions>

      <Overview>
        <Card>
          <h3>Monthly Usage</h3>
          <p>120 kWh</p>
        </Card>
        <Card>
          <h3>Meter Status</h3>
          <p>Active</p>
        </Card>
        <Card>
          <h3>Last Payment</h3>
          <p>{lastPayment.amount} on {lastPayment.date}</p>
        </Card>
      </Overview>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 16px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;

const Logo = styled.img`
  width: 20px;
  height: auto;
`;

const Profile = styled.div`
  width: 32px;
  height: 32px;
  background: #004080; 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #CD5C08;
  font-weight: 800;

  span {
    margin-top: 4px;
    font-size: 0.85rem;
  }

  &:hover {
    color: #004080;
  }
`;

const Overview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  color: white;
`;

const Card = styled.div`
  background: #CD5C08;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;

  h3 {
    margin-top: 1rem;
    font-size: 1rem;
    color: black; 
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px;
    color: #fff;
  }
`;

export default HomePage;
