import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
// import { Energy } from '../../assets/images'
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
// import { api } from '../../components/hooks';

import { useNavigate } from "react-router-dom";
import { Alert, Loader } from "../../components/helpers";
import api from "../../service/api";

const Register = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    email: "",
    lastName: "",
    phone: "",
    estate: localStorage.getItem('estate') || "", 
    meterNumber: "",
    password: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    firstName,
    lastName,
    email,
    phone,
    estate,
    meterNumber,
    password,
    confirm,
  } = userData;

  // const r = {
  //   onChange(e: React.ChangeEvent<HTMLInputElement>) {
  //     setUserData({
  //       ...userData,
  //       [e.target.name]: e.target.value.replace(" ", ""),
  //     });
  //   },
  //   revealPassword() {
  //     setShowPassword(!showPassword);
  //   },
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value, 
      });
    },
    revealPassword() {
      setShowPassword(!showPassword);
    },
    

    async onSubmit(
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLDivElement>
    ) {
      try {
        e.preventDefault();
        if (password !== confirm) {
          setShowAlert(true);
          setAlertMessage("Passwords do not match.");
          setColor("red");
          return; 
        }
        setLoading(true);
        const data = await r.handleLogin();
        if (data?.status === "success") {
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data.details));
          setShowAlert(true);
          setAlertMessage(data?.data?.message);
          setColor("#64CCC5");
          setTimeout(() => setShowAlert(false), 3000);
          setTimeout(() => navigate("/login"), 4000);
        } else {
          setShowAlert(true);
          setAlertMessage(data?.message);
          setTimeout(() => setShowAlert(false), 3000);
        }
        setLoading(false);
      } catch (error) {}
    },

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === "Enter") {
        r.onSubmit(e);
      }
    },

    async handleLogin() {
      try {
        const body = {
          email,
          firstName,
          lastName,
          meterNumber,
          estate,
          phone,
          password,
          confirm,
        };
        // console.log(body, "body");
        const data = await api.req("/user/register", "POST", body);
       
        // console.log(data);
        return data;
      } catch (error: any) {}
    },
  };
  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Register</title>
      </Helmet>
      <Wrapper>
        <LoginContainer>
          <h1 style={{ fontSize: "1.5rem", color: "#CD5C08" }}>Register</h1>
          <p style={{ fontSize: "1rem ", color: "#CD5C08" }}>
            Enter your credentials to create an account.
          </p>

          <FormContainer onSubmit={(e) => r.onSubmit(e)}>
            <Input>
              <input
                type="text"
                placeholder="Enter your first name"
                name="firstName"
                onChange={(e) => r.onChange(e)}
                value={firstName}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type="text"
                placeholder="Enter your last name"
                name="lastName"
                onChange={(e) => r.onChange(e)}
                value={lastName}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type="text"
                placeholder="Enter your email"
                name="email"
                onChange={(e) => r.onChange(e)}
                value={email}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type="text"
                placeholder="Enter your phone number"
                name="phone"
                onChange={(e) => r.onChange(e)}
                value={phone}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type="text"
                placeholder="Enter your meter number"
                name="meterNumber"
                onChange={(e) => r.onChange(e)}
                value={meterNumber}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type="text"
                placeholder="Enter your estate"
                name="estate"
                onChange={(e) => r.onChange(e)}
                value={estate}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
            </Input>
            <Input>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                className="password"
                onChange={(e) => r.onChange(e)}
                value={password}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
              {password.length > 0 && (
                <Icon onClick={() => r.revealPassword()}>
                  {showPassword ? (
                    <MdOutlineVisibility
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Hide password"
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Show password"
                    />
                  )}
                </Icon>
              )}
            </Input>
            <Input>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Comfirm your password"
                name="confirm"
                className="password"
                onChange={(e) => r.onChange(e)}
                value={confirm}
                onKeyDown={(e) => r.handleKeyPress(e)}
                style={{
                  border: "2px solid #CD5C08",
                  borderRadius: "20px",
                  color: "black",
                }}
              />
              {password.length > 0 && (
                <Icon onClick={() => r.revealPassword()}>
                  {showPassword ? (
                    <MdOutlineVisibility
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Hide password"
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      size={"1.5em"}
                      color={"#213F7D"}
                      title="Show password"
                    />
                  )}
                </Icon>
              )}
            </Input>
            {loading ? (
              <Button>
                <Loader color={"#fff"} />
              </Button>
            ) : (
              <Button
                onClick={(e) => r.onSubmit(e)}
                className={
                  !email || !password || password !== confirm
                    ? "disabled"
                    : undefined
                }
              >
                Register
              </Button>
            )}
          </FormContainer>
          <p style={{ fontSize: "1rem", marginTop: "1rem" }}>
            Already have an account?{" "}
            <span
              style={{ color: "#CD5C08" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </span>
          </p>
        </LoginContainer>
      </Wrapper>
      {showAlert && <Alert message={alertMessage} color={color} />}
    </AppContainer>
  );
};

/** STYLES */
const AppContainer = styled.div`
  background-color: #F8F0E5;
  min-height: 100vh;
  width: 100%;
  display: "flex";
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
// const ImageWrapper = styled.div`

// `;
// const Image = styled.img`
// width: 80px;
// height: 80px;
// `;

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 50px 30px;
  background-color: #F8F0E5;
  border-radius: 10px;
  margin: 1rem;

  h1 {
    font-size: 19px;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    align-self: center;
    color: #cd5c08;
    margin: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  }
`;

const FormContainer = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    margin-top: 30px;
    border: 1px solid #CD5C08;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #fde406;
    padding: 1rem;
    outline: "";
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = styled.div`
  position: relative;
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 15px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #cd5c08;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 2.5rem;
  display: flex;
  right: 1rem !important;
  cursor: pointer;
`;

export default Register;
