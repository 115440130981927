import React from "react";
import { PaymentProps } from "../../types";
import styled from "styled-components";
import Steppers from "./stepper";
import { Screen } from "../styles";

const Amount = ({ amount, step, r }: PaymentProps) => {
  return (
    <Box>
      <Card>
        <Steppers step={step} />
        <FormContainer>
          <input
            type="text"
            placeholder="Enter amount"
            name="amount"
            onChange={(e) => r.onChange(e)}
            value={amount}
            style={{
              border: "2px solid #CD5C08",
            }}
          />
          <ButtonWrapper>
            <Button onClick={() => r.prevStep()}>Back</Button>
            <Button
              className={!amount ? "disabled" : undefined}
              onClick={() => r.nextStep()}
            >
              Next
            </Button>
          </ButtonWrapper>
        </FormContainer>
      </Card>
    </Box>
  );
};

const Box = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #CD5C08;
  padding: 3rem 0;
`;

const Card = styled.div`
  width: 350px;
  margin: 6rem auto;
  padding: 3rem 2rem;
  background-color: #fff;

  ${Screen.smallPhone`
width: 90%

`}
`;
const FormContainer = styled.form`
  margin: 10px auto;

  input {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115c;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 15px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #cd5c08;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default Amount;
