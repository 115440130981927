import React from "react";
import { AiOutlineFall } from "react-icons/ai";

const TransactionComponent = ({
  getSingleTransaction,
  amount,
  meterNumber,
  product,
  time,
  color,
  id,  
}) => {
  return (
    <div
      style={{
        width: "100%",
        flexDirection: "row",
        gap: "1rem",
        alignItems: "center",
        display: "flex",
        padding: "1rem",
        justifyContent: "space-between",
        background: "none",
        cursor: "pointer",
      }}
      onClick={() => getSingleTransaction(id)} 
    >
      <div
        style={{
          width: "2.5rem",
          height: "2.5rem",
          background: "#F5F5F5",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <AiOutlineFall color={color} size={20} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          background: "none",
          height: "100%",
        }}
      >
        <div style={{ marginBottom: "0.3rem" }}>
          <p style={{ fontWeight: "bold" }}>{meterNumber}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            width: "100%",
          }}
        >
          <p style={{ fontSize: "0.8rem" }}>{product}</p>
          <p style={{ fontSize: "0.8rem" }}>{time}</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <p style={{ color }}>{amount}</p>
      </div>
    </div>
  );
};

export default TransactionComponent;
