import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Alert, Loader } from '../../components/helpers';
import api from '../../service/api';

const GenerateToken = () => {
  const [userData, setUserData] = useState({
    meterNumber: "",
    amount: "",
    meterType: "",
    powerType: ""
  });

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [color, setColor] = useState("");
  const [token, setToken] = useState(""); 
  const [showModal, setShowModal] = useState(false); 
  const { meterNumber, amount, meterType, powerType } = userData;

  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value.replace(" ", "")
      });
    },

    async onSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      setLoading(true);
      try {
        const payload = {
          meterNumber,
          amount,
          meterType,
          powerType
        };

        const data = await api.req("/transaction/generate_token", "POST", payload);
        if (data?.status === "success") {
          setShowAlert(false); 
          setToken(data?.data?.token); 
          setShowModal(true); 
        } else {
          setShowAlert(true);
          setAlertMessage(data?.message || "Failed to generate token");
          setColor("#FF0000");
          setTimeout(() => setShowAlert(false), 3000);
        }
      } catch (error) {
        setShowAlert(true);
        setAlertMessage("An error occurred while generating the token");
        setColor("#FF0000");
        setTimeout(() => setShowAlert(false), 3000);
      } finally {
        setLoading(false);
      }
    },

    copyToClipboard() {
      navigator.clipboard.writeText(token);
      setAlertMessage("copied to clipboard!");
      setColor("#64CCC5");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 2000);
    }
  };

  return (
    <AppContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Generate Token</title>
      </Helmet>

      <Wrapper>
        <FormContainer onSubmit={(e) => r.onSubmit(e)}>
          <h1>Meter Number</h1>
          <input
            type="text"
            placeholder="Enter Meter Number"
            name="meterNumber"
            onChange={(e) => r.onChange(e)}
            value={meterNumber}
          />

          <h1>Amount</h1>
          <input
            type="text"
            placeholder="Enter Amount"
            name="amount"
            onChange={(e) => r.onChange(e)}
            value={amount}
          />

          <h1>Meter Type</h1>
          <Select>
            <select
              name="meterType"
              onChange={(e) => r.onChange(e)}
              value={meterType}
            >
              <option></option>
              <option value="Dual">Dual</option>
              <option value="Single">Single</option>
              <option value="Water">Water</option>
            </select>
          </Select>

          <h1>Power Type</h1>
          <Select>
            <select
              name="powerType"
              onChange={(e) => r.onChange(e)}
              value={powerType}
            >
              <option></option>
              <option value="Grid">Grid</option>
              <option value="Generator">Generator</option>
            </select>
          </Select>

          {loading ? (
            <Button>
              <Loader color="#fff" />
            </Button>
          ) : (
            <Button type="submit" className={!meterNumber || !amount || !meterType || !powerType ? 'disabled' : undefined}>
              Generate Token
            </Button>
          )}
        </FormContainer>
      </Wrapper>

      {/* Modal to show token */}
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <TokenDisplay>{token}</TokenDisplay>
            <Button onClick={r.copyToClipboard}>Copy Token</Button>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </ModalContent>
        </ModalOverlay>
      )}

      {showAlert && <Alert message={alertMessage} color={color} />}
    </AppContainer>
  );
};


const AppContainer = styled.div`
  background-color: "none";
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;    
`;

const Wrapper = styled.div`
  width: 50%;  
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  width: 100%; 
  max-width: 500px; 
  text-align: center;

  h1 {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 20px;
    border: 1px solid #dbd9d9;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #0a3d94;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Button = styled.button`
  display: flex;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;
  border: none;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #CD5C08;
  }
`;

const Select = styled.div`
  select {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
    cursor: pointer;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }
`;

const TokenDisplay = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #0a3d94;
  margin-bottom: 20px;
`;

export default GenerateToken;
