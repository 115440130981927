import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainPages } from "./pages";
import { GlobalStyle } from "./components/styles";
import { AdminRoutes, ProtectedRoutes } from "./routes";

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" index={true} element={<MainPages.Landing />} />
          <Route path="/login" element={<MainPages.Login />} />
          <Route path="/register" element={<MainPages.Register />} />
          <Route path="/dashboard" element={<MainPages.Dashboard />} />
          <Route path="/homePage" element={<MainPages.HomePage />} />
          <Route path="/payment" element={<MainPages.Payment />} />
          <Route path="/receipt/:id" element={<MainPages.Receipt />} />
          <Route path="/su/admin/login" element={<MainPages.AdminLogin />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/admin/*" element={<AdminRoutes />} />
          </Route>
        </Routes>
      </Router>
    </>
  );

}
export default App;
